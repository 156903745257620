<template>
  <app-page-primary
    :is-loading="isLoading"
  >
    <template #header>
      <v-btn
        small
        icon
        tile
        outlined
        color="primary"
        class="mr-1"
        @click="$router.push({ name: 'school.dashboard' })"
      >
        <v-icon>
          mdi-chevron-left
        </v-icon>
      </v-btn>
      <div
        class="school-calendar--user--name text-h6 text-break mx-1 align-center d-flex"
        :class="{ 'lh-1': $vuetify.breakpoint.xsOnly }"
      >
        {{ user.name }}
      </div>
      <v-spacer />
      <div class="d-flex">
        <v-btn
          v-if="enableRefresh"
          class="mr-2"
          color="primary"
          small
          tile
          outlined
          @click="refreshCalendar"
        >
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
        <v-menu
          bottom
          offset-y
          right
          :close-on-content-click="false"
        >
          <template #activator="{ on }">
            <v-btn
              outlined
              color="primary"
              small
              tile
              v-on="on"
            >
              <v-icon>mdi-menu</v-icon>
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item>
              <v-list-item-title>
                <v-checkbox
                  v-model="showPlans"
                  label="Показать планы"
                  dense
                  hide-details=""
                  class="mt-0"
                />
              </v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-title>
                <v-checkbox
                  v-model="showMoves"
                  label="Показать тренировки"
                  dense
                  hide-details=""
                  class="mt-0"
                />
              </v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-title>
                <v-checkbox
                  v-model="showSummary"
                  label="Показать сводку"
                  dense
                  hide-details=""
                  class="mt-0"
                />
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </template>

    <template #default>
      <div
        class="h-100 w-100"
      >
        <div
          ref="infiniteScroll"
          class="h-100 w-100 app-calendar overflow-x-auto overflow-y-auto"
          :class="{ 'no-summary': !showSummary }"
          @scroll="infiniteScroll"
        >
          <div>
            <app-calendar-header />
            <div ref="infiniteScrollInner">
              <app-calendar-row
                v-for="(item) in calendarRows"
                :key="item.start"
                :start-date="$moment().tz($moment.tz.guess()).startOf('isoweek').tz('UTC').add(item.start, 'weeks')"
                :plans="item.plans"
                :moves="item.moves"
                :user-id="user.id"
                :enable-school-functions="true"
                :enable-laps="true"
                :show-plans="showPlans"
                :show-moves="showMoves"
                :current="item.start === 0"
                :class="item.start < 0 ? 'app-calendar-row-past' : ''"
                @addPlan="handleAddPlan($event, item)"
              />
            </div>
          </div>
        </div>
      </div>
    </template>
  </app-page-primary>
</template>

<script>
  import axios from 'axios'
  import AppCalendarRow from "../components/AppCalendar/AppCalendarRow"
  import AppCalendarHeader from "../components/AppCalendar/AppCalendarHeader"
  import AppPagePrimary from '../components/AppPage/AppPagePrimary'
  import refreshCalendar from "./mixins/refreshCalendar"

  export default {
    name: 'SchoolUserCalendar',
    components: {
      AppPagePrimary,
      AppCalendarHeader,
      AppCalendarRow
    },
    mixins: [
      refreshCalendar
    ],
    props: {
      id: {
        type: [Number, String],
        default: -1
      }
    },
    data() {
      return {
        isLoading: false,
        calendarRows: [],
        showPlans: true,
        showMoves: false,
        showSummary: false,
        debug: 0,
        user: {
          name: ''
        },
        redirected: false
      }
    },
    computed: {

    },
    watch: {
      showPlans (newVal) {
        localStorage.showPlans = newVal;
      },
      showMoves (newVal) {
        localStorage.showMoves = newVal;
      },
      showSummary (newVal) {
        localStorage.showSummary = newVal;
      },
    },
    mounted () {
      this.addSmth();
      if (localStorage.showPlans) {
        this.showPlans = (localStorage.showPlans === 'true');
      }
      if (localStorage.showMoves) {
        this.showMoves = (localStorage.showMoves === 'true');
      }
      if (localStorage.showSummary) {
        this.showSummary = (localStorage.showSummary === 'true');
      }
    },
    methods: {
      handleAddPlan (plan, item) {
        item.plans.push(plan);
      },
      addSmth () {
        this.infiniteScroll();
        this.$nextTick(function () {
          if ((this.$refs.infiniteScroll.offsetHeight > this.$refs.infiniteScrollInner.offsetHeight && this.calendarRows.length < 20) || (this.$refs.infiniteScroll.scrollTop === 0)) {
            this.addSmth();
          }
        })
      },
      addRows (prepend = false) {
        this.isLoading = true;
        let first = 0;
        if (this.calendarRows.length !== 0) {
          if (prepend) {
            first = this.calendarRows[0].start - 4;
          } else {
            first = this.calendarRows[this.calendarRows.length - 1].start + 4;
          }
        }

        for (let i = 0; i < 4; i++) {
          let row = {
            start: 0,
            moves: [],
            plans: []
          };
          if (this.calendarRows.length !== 0) {
            if (prepend) {
              row.start = this.calendarRows[0].start - 1;
              this.calendarRows.unshift(row);
            } else {
              row.start = this.calendarRows[this.calendarRows.length - 1].start + 1;
              this.calendarRows.push(row);
            }
          } else {
            this.calendarRows.push(row);
          }
        }

        axios({
          url: '/api/calendar/' + first,
          params: {
            user_id: this.id
          },
          method: 'GET',
        })
          .then(
            (res) => {
              this.user = res.data.user;
              res.data.calendar.forEach((week) => {
                this.calendarRows.some((row) => {
                  if (row.start === week.start) {
                    row.plans = week.plans;
                    row.moves = week.moves;
                  }
                  return row.start === week.start;
                })
              });
            })
          .catch(
            () => {
              if (!this.redirected) {
                this.redirected = true;
                this.$router.push({ name: 'school.dashboard' });
              }
              //console.log(res);
            })
          .finally(
            () => {
              this.isLoading = false;
            });
      },
      infiniteScroll () {
        let bottomOfWindow = this.$refs.infiniteScroll.scrollTop + this.$refs.infiniteScroll.clientHeight >= this.$refs.infiniteScroll.scrollHeight - 25;
        if (bottomOfWindow) {
          this.addRows();
          this.$nextTick(function () {
            if (this.calendarRows.length > 20) {
              this.calendarRows.splice(0, 4);
            }
          });
        } else {
          if (this.$refs.infiniteScroll.scrollTop === 0) {
            let lastHeight = this.$refs.infiniteScroll.scrollHeight;
            this.addRows(true);
            this.$nextTick(function () {
              let newHeight = this.$refs.infiniteScroll.scrollHeight;
              this.$refs.infiniteScroll.scrollTop = (newHeight - lastHeight);
              if (this.calendarRows.length > 20) {
                this.calendarRows.splice(-4, 4);
              }
            });
          }
        }

      }
    }
  }
</script>

<style scoped lang="scss">
.school-calendar--user--name {
  font-size: 1rem !important;
  min-height: 100%;
}

@media (min-width: map-get($grid-breakpoints, sm)) {
  .school-calendar--user--name {
    font-size: 1.25rem !important;
  }
}
</style>
