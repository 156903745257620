<template>
  <app-page-primary
    :is-loading="isLoading"
  >
    <template #header>
      <div
        class="text-h6"
      >
        Школа
      </div>

      <v-spacer />

      <div class="d-flex">
        <v-btn
          v-if="enableRefresh"
          class="mr-2"
          color="primary"
          small
          tile
          outlined
          @click="refreshDashboard"
        >
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
        <v-btn
          class="mr-2"
          color="primary"
          small
          tile
          outlined
          @click="week--"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <v-btn
          class="mr-2"
          color="primary"
          small
          tile
          outlined
          @click="week++"
        >
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
        <v-menu
          bottom
          offset-y
          right
          :close-on-content-click="false"
        >
          <template #activator="{ on }">
            <v-btn
              color="primary"
              small
              tile
              outlined
              v-on="on"
            >
              <v-icon>mdi-menu</v-icon>
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item>
              <v-list-item-title>
                <v-checkbox
                  v-model="showPlans"
                  label="Показать планы"
                  dense
                  hide-details=""
                  :ripple="false"
                  class="mt-0"
                />
              </v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-title>
                <v-checkbox
                  v-model="showMoves"
                  label="Показать тренировки"
                  dense
                  hide-details=""
                  :ripple="false"
                  class="mt-0"
                />
              </v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-title>
                <v-checkbox
                  v-model="showSummary"
                  label="Показать сводку"
                  dense
                  hide-details=""
                  class="mt-0"
                />
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </template>
    <template #default>
      <div
        class="dashboard-current-week pl-1 pb-2 text-caption font-weight-bold"
      >
        {{ $moment().tz($moment.tz.guess()).startOf('isoweek').add(week, 'weeks').format('DD MMMM') }} &mdash; {{ $moment().tz($moment.tz.guess()).endOf('isoweek').add(week, 'weeks').format('DD MMMM') }} ({{ week === 0 ? 'текущая' : week > 0 ? `+${week}` : `${week}` }})
      </div>
      <div
        :key="keyDashboard"
        class="h-100 w-100 pt-5"
      >
        <div
          id="school-dashboard-scroll"
          class="h-100 w-100 app-calendar member-calendar overflow-x-auto"
          :class="{ 'no-summary': !showSummary }"
        >
          <div>
            <!--<app-calendar-header />-->
            <div
              v-for="(user, key) in users"
              :id="`user-${user.id}`"
              :key="key"
              class="dashboard-panel"
            >
              <div
                class="dashboard-user d-flex"
                :class="$vuetify.breakpoint.lgAndUp && drawer ? 'dashboard-user--dense' : ''"
              >
                <div
                  class="d-flex align-center"
                >
                  <div
                    class="member-info pa-1 font-weight-black d-flex align-center text-caption c-pointer flex-grow-1"
                    @click="toggleUserPanel(user, key)"
                  >
                    <v-avatar
                      size="32"
                      class="mr-2"
                      color="secondary"
                    >
                      <v-img
                        v-if="user.avatar"
                        :src="user.avatar"
                      />
                      <v-icon
                        v-else
                        color="primary"
                      >
                        mdi-account
                      </v-icon>
                    </v-avatar>
                    <div>
                      <div class="lh-1 mb-1">
                        {{ user.name }}
                      </div>
                      <div class="lh-1 font-weight-regular">
                        Планов:
                        <span
                          class="font-weight-bold"
                          :class="getPlansCount(user.plans) === 0 && !isLoading ? 'error--text' : getPlansCount(user.plans) >= 5 ? 'success--text' : ''"
                        >
                          {{ isLoading ? '?' : getPlansCount(user.plans) }}
                        </span>
                      </div>
                    </div>
                    <v-btn
                      icon
                      small
                      class="ml-auto"
                    >
                      <v-icon
                        size="24"
                        color="primary"
                      >
                        {{ openPanel[key] ? 'mdi-minus' : 'mdi-plus' }}
                      </v-icon>
                    </v-btn>
                  </div>
                  <div
                    class="ml-3 mr-5"
                  >
                    <v-btn
                      icon
                      small
                      @click="$router.push({ name: 'school.user.calendar', params: { id: user.id } })"
                    >
                      <v-icon
                        size="24"
                        color="primary"
                      >
                        mdi-open-in-new
                      </v-icon>
                    </v-btn>
                  </div>
                </div>
              </div>
              <app-calendar-header
                v-if="user.open"
              />
              <app-calendar-row
                v-if="user.open"
                :start-date="$moment().tz($moment.tz.guess()).startOf('isoweek').tz('UTC').add(week, 'weeks')"
                :plans="user.plans"
                :moves="user.moves"
                :user-id="user.id"
                :enable-school-functions="true"
                :enable-laps="true"
                :show-plans="showPlans"
                :show-moves="showMoves"
                :current="week===0"
                :class="week < 0 ? 'app-calendar-row-past' : ''"
                @addPlan="handleAddPlan"
              />
            </div>
          </div>
        </div>
      </div>
    </template>
  </app-page-primary>
</template>

<script>
  import axios from 'axios'
  import AppCalendarRow from "../components/AppCalendar/AppCalendarRow"
  import AppCalendarHeader from "../components/AppCalendar/AppCalendarHeader"
  import AppPagePrimary from '../components/AppPage/AppPagePrimary'
  import refreshCalendar from "./mixins/refreshCalendar"

  export default {
    name: 'SchoolDashboard',
    components: {
      AppPagePrimary,
      AppCalendarHeader,
      AppCalendarRow
    },
    mixins: [
      refreshCalendar
    ],
    props: {
      drawer: {
        type: [Boolean],
        default: null
      }
    },
    data() {
      return {
        keyDashboard: 0,
        isLoading: false,
        week: 0,
        headers: [
          {
            text: 'ID',
            align: 'start',
            sortable: false,
            value: 'id',
          },
          {
            text: 'Имя',
            align: 'start',
            sortable: false,
            value: 'name',
          },
          {
            text: 'E-mail',
            align: 'start',
            sortable: false,
            value: 'email',
          },
          {
            text: 'Дата регистрации',
            align: 'start',
            sortable: false,
            value: 'created_at',
          },
          {
            text: 'Тренировки',
            value: 'moves',
          }
        ],
        has_error: false,
        users: [],
        openPanel: [],
        showPlans: true,
        showMoves: false,
        showSummary: false
      }
    },

    watch: {
      'week': function () {
        this.getUsers()
      },
      showPlans (newVal) {
        localStorage.showPlans = newVal;
      },
      showMoves (newVal) {
        localStorage.showMoves = newVal;
      },
      showSummary (newVal) {
        localStorage.showSummary = newVal;
      }
    },

    mounted() {
      if (localStorage.showPlans) {
        this.showPlans = (localStorage.showPlans === 'true');
      }
      if (localStorage.showMoves) {
        this.showMoves = (localStorage.showMoves === 'true');
      }
      if (localStorage.showSummary) {
        this.showSummary = (localStorage.showSummary === 'true');
      }
      this.getUsers();
    },

    methods: {
      getOpenPanel (key) {
        return this.openPanel[key];
      },
      toggleUserPanel (user, key) {
        this.openPanel[key] = !this.openPanel[key];
        user.open = !user.open;
        this.$nextTick(() => {
          if (!this.openPanel[key]) {
            // при закрытии пролистать до верха блока, если он выше границы видимости
            try {
              if (document.getElementById(`user-${user.id}`).offsetTop < document.getElementById('school-dashboard-scroll').scrollTop) {
                document.getElementById('school-dashboard-scroll').scrollTop = document.getElementById(`user-${user.id}`).offsetTop - document.getElementById('school-dashboard-scroll').offsetTop;
              }
            } catch (e) {
              //
            }
          }
        });
      },
      handleAddPlan (plan) {
        const user = this.users.find((user) => { return user.id === plan.user_id });
        user.plans.push(plan);
      },
      getPlansCount (plans) {
      return plans.filter((plan) => { return this.$moment(plan.start).tz(this.$moment.tz.guess()) >= this.$moment().tz(this.$moment.tz.guess()).startOf('isoweek').add(this.week, 'weeks') && this.$moment().tz(this.$moment.tz.guess()).startOf('isoweek').add(this.week + 1, 'weeks') > this.$moment(plan.start).tz(this.$moment.tz.guess()) && plan.description }).length
      },
      getUsers() {
        this.keyDashboard += 1;
        this.isLoading = true;
        axios({
          url: '/api/members/' + this.week,
          method: 'GET'
        })
          .then(
            (res) => {
              this.users = res.data.users;
              if (this.users.length !== this.openPanel.length) {
                this.openPanel = [];
                this.users.map((item) => {
                  this.openPanel.push(false);
                  this.$set(item, 'open', false);
                });
              } else {
                this.users.map((item, key) => {
                  this.$set(item, 'open', this.openPanel[key]);
                });
              }
          })
          .catch(
            () => {
              //console.log(res);
            })
          .finally(
            () => {
              this.isLoading = false;
            })
      }
    }
  }
</script>

<style scoped lang="scss">
  .app-calendar {
    &>div {
      min-width: 1200px;
    }
    .dashboard-user {
      color: var(--v-primary-base);
      background: var(--v-secondary-base);
      //border-top: 1px solid var(--v-primary-base);
      height: 40px;
      position: sticky;
      top: 0;// $app-calendar-header-height;
      z-index: 6;
     // border-top: 1px solid black;
      &>div {
        position: sticky;
        left: 0;
        min-width: 320px;
        max-width: 95vw;
        width: 100%;
      }
    }
    .dashboard-user--dense {
      &>div {
        max-width: calc(95vw - 256px);
      }
    }
    .dashboard-panel {
      border-bottom: 1px solid black;
    }
  }
  .dashboard-current-week {
    max-height: 20px;
    line-height: 1;
    position: absolute;
    width: 100%;
    border-bottom: 1px solid black;
  }
</style>

<style lang="scss">
  .member-calendar {
    overflow-y: scroll;
    .app-calendar-header {
      top: 40px;
    }
    .app-calendar-col-header {
      top: 40px + $app-calendar-header-height * 1;
    }
    .app-calendar-summary-header {
      top: 40px + $app-calendar-header-height * 1;
    }
    .app-calendar-summary-body {
      top: 40px + $app-calendar-header-height * 2;
    }
  }
</style>
